import React, { Component } from 'react'

// Services
import { resetPassword } from '../../services/auth'

export default class PasswordResetForm extends Component {
  state = {
    email: '',
  }

  /**
   * Handle input change
   * @param {Object} event
   */
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /**
   * Submit forms
   * @param {Object} event
   */
  handleSubmit = async event => {
    event.preventDefault()
    try {
      const { email } = this.state
      await resetPassword({ email })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    return (
      <div className="container mx-auto mt-5 text-slide-in">
        <div className="grid grid-cols-12">
          <div className=" col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 text-center">
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                <input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="email"
                  placeholder="Escribe tu correo electrónico"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="grid grid-cols-12 mt-5">
                <div className="col-span-12">
                  <button
                    className="default-button py-3 w-full px-8 mb-64 rounded"
                    type="submit"
                  >
                    Recuperar contraseña
                  </button>
                </div>
                <div className="mb-10"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
