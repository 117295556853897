import React from 'react'

//Components
import Layout from '../../components/layout/layout'
import PasswordResetForm from '../../components/forms/password-reset-form'
import Title from '../../components/global/title'
import SEO from '../../components/seo'

const PasswordResetPage = () => {
  return (
    <Layout>
      <SEO title="¿Olvidaste tu contraseña?" />
      <Title
        main="¿Olvidaste tu contraseña?"
        subTitle="Introduce tu correo y te enviaremos un link para que recuperes tu contraseña."
        options={{ center: true }}
      />
      <PasswordResetForm />
    </Layout>
  )
}

export default PasswordResetPage
